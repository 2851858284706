import { useSelector } from "react-redux";
import { Get, Post } from "../AxiosCRUDFunctions";
import { apiHeader, BaseURL } from "../config";

const useApi = () => {
  const accessToken = useSelector((state) => state?.authReducer?.accessToken);

  const login = async ({ code, phoneNumber, password }) => {
    const response = await Post(
      BaseURL("Auth/login"),
      {
        countryCode: code,
        cellNumber: phoneNumber,
        password: password,
        deviceToken: "",
        version: "",
      },
      apiHeader()
    );
    return response;
  };

  const signUp = async ({
    name,
    code,
    phoneNumber,
    email,
    password,
    communityName,
    communityAddress,
  }) => {
    const response = await Post(
      BaseURL("Auth/Register"),
      {
        userName: name,
        countryCode: code,
        cellNo: phoneNumber,
        email,
        password,
        communityName,
        communityAddress,
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const signUpJoin = async ({
    communityCode,
    name,
    code,
    phoneNumber,
    email,
    cnic,
    communitySeriesDetailId,
    password,
    propertyTypeId,
  }) => {
    const response = await Post(
      BaseURL("Community/JoinCommunity"),
      {
        communityCode,
        name,
        countryCode: code,
        cellNo: phoneNumber,
        emailAddress: email,
        cnic,
        communitySeriesDetailId,
        password,
        propertyTypeId,
        deviceToken: "",
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const generateOtp = async ({ code, phoneNumber }) => {
    const response = await Post(
      BaseURL("Auth/SendOTPSignup"),
      {
        countryCode: code,
        cellNumber: phoneNumber,
      },
      apiHeader()
    );
    return response;
  };

  const verifyOtp = async ({ otpCode }) => {
    const response = await Post(
      BaseURL("Auth/verify-otp"),
      {
        otpCode: otpCode,
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const verifyCommunity = async ({ code }) => {
    const response = await Post(
      BaseURL("Community/VerifyCommunity"),
      {
        communityCode: code,
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const SaveAmmenityRanges = async ({ form }) => {
    const response = await Post(
      BaseURL("Community/SaveAmmenityRanges"),
      form,
      apiHeader(accessToken)
    );
    return response;
  };

  const UploadAmmenitySeriesExcel = async ({ formData }) => {
    const response = await Post(
      BaseURL("Community/UploadAmmenitySeriesExcel"),
      formData,
      apiHeader(accessToken, true)
    );
    return response;
  };

  const DownloadAmmenitySeriesExcel = async () => {
    const response = await Get(
      BaseURL("Community/DownloadAmmenitySeriesExcel"),
      accessToken
    );
    return response;
  };

  const GetAllAmmenities = async () => {
    const response = await Get(BaseURL("Setup/GetAllAmmenities"), accessToken);
    return response;
  };

  const GetAllPropertyType = async () => {
    const response = await Get(
      BaseURL("Setup/GetAllPropertyType"),
      accessToken
    );
    return response;
  };

  const GetAmmenityDetailBySeriesDetailId = async ({ id }) => {
    const response = await Get(
      BaseURL(
        `Community/GetAmmenityDetailBySeriesDetailId?communitySeriesDetailId=${id}`
      ),
      accessToken
    );
    return response;
  };

  const GetAllRole = async () => {
    const response = await Get(BaseURL("Role/GetAllRole"), accessToken);
    return response;
  };

  const addUser = async ({ data }) => {
    const response = await Post(
      BaseURL("user/add-user"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const updateUser = async ({ data }) => {
    const response = await Post(
      BaseURL("user/update-user"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const getAllSeriesByCommunity = async () => {
    const response = await Get(
      BaseURL(`Setup/GetAllSeriesByCommunityId`),
      accessToken
    );
    return response;
  };

  const GetAllApprovalsListing = async (data) => {
    const response = await Post(
      BaseURL("Approval/GetApprovalRequestList"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const GetApprovalById = async (id) => {
    const response = await Get(
      BaseURL(`Approval/GetByApprovalRequestID?ApprovalRequestId=${id}`),
      accessToken
    );
    return response;
  };

  const handleApprovalRequest = async ({ data }) => {
    const response = await Post(
      BaseURL("Approval/ApprovedJoinCommunityByApprovalRequestId"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const GetAllRoleListing = async ({ data }) => {
    const response = await Post(
      BaseURL("Role/GetRoleList"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const GetAllRoleModules = async () => {
    const response = await Get(BaseURL("Role/getAllModules"), accessToken);
    return response;
  };

  const GetRoleById = async ({ id }) => {
    const response = await Get(BaseURL(`Role/GetByID?ID=${id}`), accessToken);
    return response;
  };

  const SaveRolePermission = async ({ data }) => {
    const response = await Post(
      BaseURL("Role/savePermissions"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const UpdateRolePermission = async ({ data }) => {
    const response = await Post(
      BaseURL("Role/updatePermissions"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  // all announcements api starts from here
  const getAllAnnouncementListing = async ({ data }) => {
    const response = await Post(
      BaseURL("Announcement/GetAnnouncementList"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const creatAnnouncement = async ({ data }) => {
    const response = await Post(
      BaseURL("Announcement/AddAnnouncement"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const GetAllApprovalStatusListing = async () => {
    const response = await Get(
      BaseURL("Setup/GetAllApprovalStatus"),
      accessToken
    );
    return response;
  };
  const getAnnouncementById = async (id) => {
    const response = await Post(
      BaseURL(`Announcement/GetAnnouncementById?AnnouncementId=${id}`),
      {},
      apiHeader(accessToken)
    );

    return response;
  };

  const addOrEditAnnouncement = async (selectedData, params) => {
    const response = await Post(
      BaseURL(
        `Announcement/${
          selectedData != null ? "UpdateAnnouncement" : "AddAnnouncement"
        }`
      ),
      params,
      apiHeader(accessToken)
    );

    return response;
  };
  const deleteAnnouncement = async (id) => {
    const response = await Post(
      BaseURL("Announcement/DeleteAnnouncement"),
      { announcementId: id },
      apiHeader(accessToken)
    );
    return response;
  };
  // communityservices start from here
  const getAllCommunityServiceListing = async (params) => {
    const response = await Post(
      BaseURL("CommunityService/GetServicesList"),
      params,
      apiHeader(accessToken)
    );
    return response;
  };
  const getCommunityServiceById = async (id) => {
    const response = await Post(
      BaseURL(`CommunityService/GetServicesById?ServiceCategoryId=${id}`),
      {},
      apiHeader(accessToken)
    );

    return response;
  };
  const addOrEditCommunityService = async (selectedData, params) => {
    console.log("params inside use api service", params);
    const response = await Post(
      BaseURL(
        `CommunityService/${selectedData ? "UpdateService" : "AddService"}`
      ),
      params,
      apiHeader(accessToken)
    );
    return response;
  };
  const deleteCommunityServiceCategory = async (id) => {
    const response = await Post(
      BaseURL("CommunityService/DeleteServiceCategory"),
      { serviceCategoryId: id },
      apiHeader(accessToken)
    );
    return response;
  };

  const deleteCommunityService = async (params) => {
    const response = await Post(
      BaseURL("CommunityService/DeleteService"),
      params,
      apiHeader(accessToken)
    );
    return response;
  };

  const getAllServicesType = async (params) => {
    const response = await Get(BaseURL("Setup/GetAllServiceType"), accessToken);
    return response;
  };

  const getUserDetails = async () => {
    const response = await Get(BaseURL("Profile/GetUserProfile"), accessToken);
    return response;
  };

  const GetAllUserListing = async ({ data }) => {
    const response = await Post(
      BaseURL("user/GetUserList"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const UpdateUserAccess = async (data) => {
    const response = await Post(
      BaseURL("user/update-user-role"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const ChangePassword = async (data) => {
    const response = await Post(
      BaseURL("Profile/ChangePassword"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const UpdateProfile = async (data) => {
    const response = await Post(
      BaseURL("Profile/UpdateProfileSetting"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  return {
    login,
    generateOtp,
    signUp,
    verifyOtp,
    verifyCommunity,
    SaveAmmenityRanges,
    UploadAmmenitySeriesExcel,
    DownloadAmmenitySeriesExcel,
    GetAllAmmenities,
    GetAllPropertyType,
    signUpJoin,
    GetAmmenityDetailBySeriesDetailId,
    GetAllRole,
    addUser,
    updateUser,
    getAllSeriesByCommunity,
    GetAllApprovalsListing,
    GetApprovalById,
    handleApprovalRequest,
    GetAllRoleListing,
    GetAllRoleModules,
    GetRoleById,
    UpdateRolePermission,
    SaveRolePermission,
    getAllAnnouncementListing,
    GetAllApprovalStatusListing,
    creatAnnouncement,
    deleteAnnouncement,
    getAnnouncementById,
    addOrEditAnnouncement,
    getAllCommunityServiceListing,
    addOrEditCommunityService,
    deleteCommunityService,
    deleteCommunityServiceCategory,
    getCommunityServiceById,
    getAllServicesType,
    GetAllUserListing,
    UpdateUserAccess,
    ChangePassword,
    UpdateProfile,
    getUserDetails,
  };
};

export default useApi;
