import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import MyButton from "../../../../components/MyButton";
import MyInput from "../../../../components/MyInput";
import useApi from "../../../../Hooks/useApi";
import { changePasswordSchema } from "../../../../schema";
import classes from "./ChangePassword.module.css";
import PaddingContainer from "../../../../components/PaddingContainer";
import Header from "../../../../components/Header";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";

const initialValues = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCNewPassword, setShowCNewPassword] = useState(false);

  const api = useApi();
  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const params = {
          oldPassword: values?.currentPassword,
          newPassword: values?.newPassword,
        };
        const response = await api?.ChangePassword(params);
        if (response?.data?.headers?.code === 200) {
          toast.success(response?.data?.headers?.msg);
          resetForm();
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      } catch (error) {
        toast.error("Something Went Wrong");
      }
    },
  });

  const {
    touched,
    errors,
    values,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = formik;

  const handleChangeWithTouch = (field) => (value) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
  };

  const cancelHandler = () => {
    setValues(initialValues);
  };

  return (
    <PaddingContainer>
      <Header title={"Change Password"} />
      <form
        onSubmit={handleSubmit}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <MyInput
          name="currentPassword"
          label="Old Password"
          value={values?.currentPassword}
          setter={(e) =>
            handleChangeWithTouch("currentPassword")(e.target.value)
          }
          error={touched?.currentPassword && errors?.currentPassword}
          type={showOldPassword ? "text" : "password"}
          leftIcon={
            showOldPassword ? (
              <FaLockOpen
                color="var(--main-color-dark)"
                style={{ cursor: "pointer" }}
              />
            ) : (
              <FaLock
                color="var(--main-color-dark)"
                style={{ cursor: "pointer" }}
              />
            )
          }
          leftIconHandler={() => setShowOldPassword(!showOldPassword)}
        />
        <span className={classes["forgetSpan"]}>Forget Old Password</span>
        <MyInput
          name="newPassword"
          label="New Password"
          value={values?.newPassword}
          setter={(e) => handleChangeWithTouch("newPassword")(e.target.value)}
          error={touched?.newPassword && errors?.newPassword}
          type={showNewPassword ? "text" : "password"}
          leftIcon={
            showNewPassword ? (
              <FaLockOpen
                color="var(--main-color-dark)"
                style={{ cursor: "pointer" }}
              />
            ) : (
              <FaLock
                color="var(--main-color-dark)"
                style={{ cursor: "pointer" }}
              />
            )
          }
          leftIconHandler={() => setShowNewPassword(!showNewPassword)}
        />
        <MyInput
          name="confirmNewPassword"
          label="Confirm New Password"
          value={values?.confirmNewPassword}
          setter={(e) =>
            handleChangeWithTouch("confirmNewPassword")(e.target.value)
          }
          error={touched?.confirmNewPassword && errors?.confirmNewPassword}
          type={showCNewPassword ? "text" : "password"}
          leftIcon={
            showCNewPassword ? (
              <FaLockOpen
                color="var(--main-color-dark)"
                style={{ cursor: "pointer" }}
              />
            ) : (
              <FaLock
                color="var(--main-color-dark)"
                style={{ cursor: "pointer" }}
              />
            )
          }
          leftIconHandler={() => setShowCNewPassword(!showCNewPassword)}
        />
        <div className={classes.ctaWrapper}>
          <MyButton
            btnText={"Cancel"}
            className={classes.saveBtn}
            handler={cancelHandler}
          />
          <MyButton
            type="submit"
            btnText={"Update"}
            className={classes.saveBtn}
          />
        </div>
      </form>
    </PaddingContainer>
  );
};

export default ChangePassword;
