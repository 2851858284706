import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import classes from "./MyInput.module.css";

const MyInput = ({
  label,
  value,
  name,
  setter,
  error,
  leftIcon,
  leftIconHandler,
  className,
  type = "text",
  disabled,
  readOnly = false,
  ...props
}) => {
  return (
    <>
      <TextField
        id="outlined-basic"
        type={type}
        name={name}
        label={label}
        value={value}
        onChange={setter}
        variant="outlined"
        size="medium"
        className={[
          "custom-textfield w-100",
          classes["input-wrapper"],
          className,
        ].join(" ")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={leftIconHandler}>
              {leftIcon}
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        readOnly={readOnly}
        style={{ cursor: readOnly && "not-allowed" }}
        {...props}
      />
      {error && (
        <span
          style={{
            display: "inline-block",
            color: "red",
            fontSize: "14px",
            // marginBottom: "5px",
          }}
        >
          {error}
        </span>
      )}
    </>
  );
};

export default MyInput;
