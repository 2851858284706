import React, { useEffect } from "react";

import SignUp from "./pages/auth/SignUp";
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  Navigate,
  useRoutes,
} from "react-router-dom";
import { doesPathExist } from "./helpers/AccessControlHandlers";
import AuthLayout from "./layouts/AuthLayout";
import OnBoardingLayout from "./layouts/OnBoardingLayout";
import UserLayout from "./layouts/UserLayout";
import Login from "./pages/auth/Login";
import OnBoarding from "./pages/OnBoarding";
import Accounts from "./pages/user/Accounts";
import BillPage from "./pages/user/Bills/BillPage";
import BillsDetailsPage from "./pages/user/Bills/BillsDetailsPage";
import MemberBillDetails from "./pages/user/Bills/MemberBillDetails";
import Dashboard from "./pages/user/Dashboard";
import AmenitiesDashboard from "./pages/user/Dashboard/AmenitiesDashboard";
import Management from "./pages/user/Management";
import UserAccess from "./pages/user/Management/user-access";
import UserRole from "./pages/user/Management/user-role";
import MemberPage from "./pages/user/MemberPage";
import MemberDetailsPage from "./pages/user/MemberPage/MemberDetailsPage";
import MemberDuesPage from "./pages/user/MemberPage/MemberDuesPage";
import NotFound from "./pages/user/NotFound";
import Parking from "./pages/user/Parking";
import Settings from "./pages/user/Settings";
import Announcements from "./pages/user/Settings/Announcements";
import Approvals from "./pages/user/Settings/Approvals";
import Complains from "./pages/user/Settings/Complains";
import Notifications from "./pages/user/Settings/Notifications";
import Union from "./pages/user/Union/index";
import CommunityService from "./pages/user/Settings/CommunityService";
import ChangePassword from "./pages/user/Settings/ChangePassword";
import ProfileSettings from "./pages/user/Settings/ProfileSettings";

export default function Router() {
  const { token, isCommunity, isSuperUser, isLogin, menu } = useSelector(
    (state) => state?.authReducer
  );

  const navigate = useNavigate();
  const location = useLocation();
  const val = location.pathname.split("/")[1];

  useEffect(() => {
    if (location.hash.startsWith("#/")) {
      navigate(location.hash.replace("#", ""), { replace: true });
    }
  }, [location, navigate]);

  const authRoutes = useRoutes([
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        { path: "/", element: <Login />, index: true },
        { path: "signup", element: <SignUp /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  const communityRoutes = useRoutes([
    {
      path: "/",
      element: <OnBoardingLayout />,
      children: [
        { path: "/", element: <OnBoarding />, index: true },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  const userRoutes = useRoutes([
    {
      path: "/",
      element: <UserLayout />,
      children: [
        { path: "/", element: <Dashboard />, index: true },
        { path: "/amenitiesPage", element: <AmenitiesDashboard /> },
        { path: "/parking", element: <Parking /> },
        { path: "/complain", element: <Complains /> },
        { path: "/member", element: <MemberPage /> },
        { path: "/member/:id", element: <MemberDetailsPage /> },
        { path: "/member/:id/dues", element: <MemberDuesPage /> },
        { path: "/management", element: <Management /> },
        { path: "/billpaymentCollection", element: <BillPage /> },
        { path: "/overduesmembers", element: <BillPage /> },
        { path: "/advancepayments", element: <BillPage /> },
        { path: "/downloadreports", element: <BillPage /> },
        { path: "/payment&bills/details", element: <BillsDetailsPage /> },
        { path: "/payment&bills/details/:id", element: <MemberBillDetails /> },
        { path: "/accounts", element: <Accounts /> },
        { path: "/useraccess", element: <UserAccess /> },
        { path: "/userrole", element: <UserRole /> },
        { path: "/union", element: <Union /> },
        { path: "/notifications", element: <Notifications /> },
        { path: "/approvals", element: <Approvals /> },
        { path: "/announcement", element: <Announcements /> },
        { path: "/community-service", element: <CommunityService /> },
        { path: "/changePassword", element: <ChangePassword /> },
        { path: "/profileSettings", element: <ProfileSettings /> },
        { path: "/termsnconditions", element: <Settings /> },
        { path: "/privacypolicy", element: <Settings /> },
        { path: "/ledgersettings", element: <Settings /> },
        // { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  const notFoundRoutes = useRoutes([
    {
      path: "/",
      element: <UserLayout />,
      children: [
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);

  if (isLogin) {
    if (isSuperUser) {
      // if (false) {
      if (isCommunity) {
        if (doesPathExist(menu, val)) {
          return userRoutes;
        } else {
          return notFoundRoutes;
        }
      } else {
        return communityRoutes;
      }
    } else {
      if (doesPathExist(menu, val)) {
        // if (true) {
        return userRoutes;
      } else {
        return notFoundRoutes;
      }
    }
  } else {
    return authRoutes;
  }
}
