import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApi from "./useApi";

const useGeneralApi = () => {
  const api = useApi();
  const [roleList, setRoleList] = useState([]);
  const [seriesList, setSeriesList] = useState([]);
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [userDetails, setUserDetails] = useState([]);

  const approvalStatus = [
    {
      id: 1,
      label: "Pending",
    },
    {
      id: 2,
      label: "Approved",
    },
    {
      id: 3,
      label: "Reject",
    },
    {
      id: 4,
      label: "No needs for approval",
    },
  ];

  useEffect(() => {
    settledAllFunction();
  }, []);

  const settledAllFunction = async () => {
    Promise.allSettled([
      getAllRole(),
      GetAllSeriesByCommunity(),
      getAllPropertiesType(),
      getAllServicesType(),
      getUserData(),
    ]).catch((err) => {
      toast.error("Something Went Wrong");
    });
  };

  function getAllRole() {
    api?.GetAllRole().then((response) => {
      if (response?.data?.headers?.code === 200) {
        setRoleList(response?.data?.body);
      } else {
        toast.error(response?.data?.headers?.message);
        return;
      }
    });
  }

  function GetAllSeriesByCommunity() {
    api?.getAllSeriesByCommunity().then((response) => {
      if (response?.data?.headers?.code === 200) {
        setSeriesList(response?.data?.body);
      } else {
        toast.error(response?.data?.headers?.message);
        return;
      }
    });
  }

  function getAllPropertiesType() {
    api.GetAllPropertyType().then((response) => {
      if (response?.data?.headers?.code === 200) {
        const options = response?.data?.body.map((item, index) => {
          return {
            value: item?.propertyTypeId,
            label: item?.name,
          };
        });
        setPropertyTypeList(options);
      } else {
        toast.error(response?.data?.headers?.message);
        return;
      }
    });
  }

  async function getAllServicesType() {
    const response = await api?.getAllServicesType();
    if (response?.data?.headers?.code === 200) {
      const transformedData = response?.data?.body?.map((service) => {
        return { label: service?.serviceType, value: service?.serviceTypeId };
      });
      setServiceTypeOptions(transformedData);
      return response?.data?.body;
    } else {
      toast.error(response?.data?.headers?.message);
    }
  }

  async function getUserData() {
    const response = await api?.getUserDetails();
    if (response?.data?.headers?.code === 200) {
      setUserDetails(response?.data?.body);
      return response?.data?.body;
    } else {
      toast.error(response?.data?.headers?.message);
    }
  }

  return {
    roleList,
    seriesList,
    propertyTypeList,
    serviceTypeOptions,
    approvalStatus,
    userDetails,
    getUserData,
  };
};

export default useGeneralApi;
