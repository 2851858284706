import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: "",
  isLogin: false,
  isCommunity: false,
  isSuperUser: false,
  communityName: "",
  user: null,
  menu: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    otpReducer: (state, action) => {
      state.accessToken = action.payload.accessToken;
    },
    completeSetup: (state, action) => {
      state.isCommunity = true;
    },
    login: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.menu = action.payload.menu;
      state.user = action.payload.user;
      state.isCommunity = action.payload.isSetupCompleted;
      state.isSuperUser = action.payload.isSuperUser;
      state.communityName = action.payload.communityName;
      state.isLogin = true;
    },

    logout: (state) => {
      state.accessToken = "";
      state.isLogin = false;
      state.user = null;
      state.isSuperUser = false;
      state.isCommunity = false;
      state.communityName = "";
      state.menu = [];
    },
  },
});

export const { login, logout, community, otpReducer, completeSetup } =
  authSlice.actions;
export default authSlice.reducer;
