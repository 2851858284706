import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function DateField({ label = "", value, setter }) {
  const parsedValue = value ? dayjs(value) : null;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <DemoContainer components={[ "DatePicker"]}> */}
      <div>
        <DatePicker
          label={label}
          value={parsedValue}
          onChange={(newValue) => setter(newValue.toISOString())}
        />
      </div>
      {/* </DemoContainer> */}
    </LocalizationProvider>
  );
}
