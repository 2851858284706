import { Box, Grid } from "@mui/material";
import React from "react";
import { HiPlusSm } from "react-icons/hi";
import MyButton from "../MyButton";
import MyInput from "../MyInput";
import MyPhoneCode from "../MyPhoneCode";
import MySelect from "../MySelect";
import classes from "./UserDetailsForm.module.css";

const UserDetailsForm = ({
  form,
  name,
  roleList,
  typeList,
  disabled,
  children,
}) => {
  console.log(form?.values);
  return (
    <div className={classes["formCardMainWrapper"]}>
      <div className={classes["header"]}>
        <p>{name}</p>
        <HiPlusSm size={30} color="var(--black-color)" />
      </div>
      <Box
        component="form"
        onSubmit={form.handleSubmit}
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
        }}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <MyInput
          name="name"
          label="Name"
          value={form?.values.name}
          setter={form.handleChange("name")}
          error={form.errors.name}
        />
        <Grid container columnGap={1}>
          <Grid xs={2.8}>
            <MyPhoneCode
              name="countryCode"
              label="C-Code"
              value={form?.values?.countryCode}
              setter={form.handleChange("countryCode")}
              error={form.errors.countryCode}
              disabled={disabled}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={9}>
            <MyInput
              type="number"
              name="cellNo"
              label="Number"
              value={form?.values?.cellNo}
              setter={form.handleChange("cellNo")}
              error={form.errors.cellNo}
              disabled={disabled}
              readOnly={disabled}
            />
          </Grid>
        </Grid>
        <MyInput
          name="cnic"
          label="CNIC Number"
          value={form?.values?.cnic}
          setter={form.handleChange("cnic")}
          error={form.errors.cnic}
        />
        <MyInput
          name="address"
          label="Address"
          value={form?.values?.address}
          setter={form.handleChange("address")}
          error={form.errors.address}
        />
        {typeList?.length > 0 && (
          <MySelect
            name="propertyTypeId"
            label="Type"
            value={form?.values?.propertyTypeId}
            setter={(values) => form.setFieldValue("propertyTypeId", values)}
            options={typeList}
            error={form.errors.propertyTypeId}
          />
        )}
        <MySelect
          name="role"
          label="User Role"
          value={form?.values?.role}
          setter={(value) => form.setFieldValue("role", value)}
          options={roleList.map((item) => ({
            label: item?.roleName,
            value: item?.roleId,
          }))}
          error={form.errors.role}
        />

        <MyInput
          name="emergencyContactCellNo"
          label="Emergency Number"
          value={form?.values?.emergencyContactCellNo}
          setter={form.handleChange("emergencyContactCellNo")}
          error={form.errors.emergencyContactCellNo}
        />
        <MyInput
          name="emergencyContactPersonName"
          label="Emergency Person"
          value={form?.values?.emergencyContactPersonName}
          setter={form.handleChange("emergencyContactPersonName")}
          error={form.errors.emergencyContactPersonName}
        />
        {children ? (
          children
        ) : (
          <MyButton
            type="submit"
            btnText={disabled ? "Update" : "Save"}
            className={classes["btnFormStyles"]}
          />
        )}
      </Box>
    </div>
  );
};

export default UserDetailsForm;
