import React from "react";
import { Col, Row } from "react-bootstrap";
import MyButton from "../../../../../components/MyButton";
import MyCheckBox from "../../../../../components/MyCheckBox";
import MyInput from "../../../../../components/MyInput";
import PaddingContainer from "../../../../../components/PaddingContainer";
import classes from "./AddRole.module.css";
import MySelect from "../../../../../components/MySelect";

const AddRole = ({
  data,
  cancelHandler,
  handleChange,
  selectAllHandler,
  submitHandler,
  form,
  setForm,
  roleList,
}) => {
  return (
    <>
      <div className={classes["heading"]}>
        <h1>{form?.roleId === 0 ? "Add New Role" : "Update Role"}</h1>
      </div>
      <div className={classes["headerWrapper"]}>
        {form?.roleId === 0 ? (
          <MySelect
            name="roleName"
            label="RoleName"
            value={form?.roleName}
            setter={(val) => setForm((prev) => ({ ...prev, roleName: val }))}
            options={roleList.map((item) => ({
              label: item?.roleName,
              value: item?.roleId,
            }))}
          />
        ) : (
          <MyInput value={form?.roleName} label={"Role Name"} disabled={true} />
        )}
        <MyCheckBox
          value={form?.active}
          setter={(e) =>
            setForm((prev) => ({ ...prev, active: e.target.checked }))
          }
          label={"Active/Inactive"}
        />

        <MyButton
          btnText={form?.roleId === 0 ? "Create Role" : "Update Role"}
          className={classes.btnLabel}
          handler={submitHandler}
        />
        <MyButton
          btnText={"Cancel"}
          className={[classes.btnLabel, "bg-white slate-gray "].join(" ")}
          handler={cancelHandler}
        />
      </div>
      <div className={classes["otherInput"]}>
        {form?.roleName === "Others" && (
          <MyInput
            setter={(e) =>
              setForm((prev) => ({ ...prev, otherRole: e.target.value }))
            }
            value={form?.otherRole}
            label={"Role Name"}
            name="otherRole"
            disabled={form?.roleId !== 0}
          />
        )}
      </div>
      <Row className="my-30">
        {data?.map((item, index) => (
          <Col lg={12} className={classes.mainCardWrapper}>
            <AccessCard
              data={item}
              handleChange={handleChange}
              selectAllHandler={selectAllHandler}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default AddRole;

const AccessCard = ({ data, handleChange, selectAllHandler }) => {
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.cardHeader}>
        <div className={classes.cardHeading}>
          <h1>{data?.name}</h1>
          <p>(Permission)</p>
        </div>
        {!data?.subModules?.length && (
          <MyCheckBox
            value={
              data?.dbApplicationAccessControlList.length ===
              data?.selectedApplicationAccessControlList?.length
            }
            setter={() => {
              selectAllHandler({
                parentAccessId: data?.accessId,
                subModuleId: null,
              });
            }}
            label={"SelectAll"}
          />
        )}
      </div>
      <hr />
      {data?.subModules?.length ? (
        data?.subModules?.map((item, index) => (
          <AccessCardJunior
            parentAccessId={data?.accessId}
            data={item}
            handleChange={handleChange}
            selectAllHandler={selectAllHandler}
          />
        ))
      ) : (
        <div className={classes.checkBoxWrapper}>
          {data?.dbApplicationAccessControlList?.map((item, index) => (
            <MyCheckBox
              value={
                data?.selectedApplicationAccessControlList.findIndex(
                  (i) => i.value === item.value
                ) > -1
              }
              label={item?.label}
              setter={() =>
                handleChange({
                  parentAccessId: data?.accessId,
                  selectedItem: item,
                  subModuleId: null,
                })
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};

const AccessCardJunior = ({
  parentAccessId,
  data,
  handleChange,
  selectAllHandler,
}) => {
  return (
    <div className={[classes.cardWrapper, classes.juniorCardWrapper].join(" ")}>
      <div className={classes.cardHeader}>
        <div className={classes.cardHeading}>
          <h1>{data?.name}</h1>
          <p>(Permission)</p>
        </div>
        <MyCheckBox
          value={
            data?.dbApplicationAccessControlList.length ===
            data?.selectedApplicationAccessControlList?.length
          }
          setter={() => {
            selectAllHandler({
              parentAccessId: parentAccessId,
              subModuleId: data?.accessId,
            });
          }}
          label={"SelectAll"}
        />
      </div>
      <div className={classes.checkBoxWrapper}>
        {data?.dbApplicationAccessControlList?.map((item, index) => (
          <MyCheckBox
            value={
              data?.selectedApplicationAccessControlList.findIndex(
                (i) => i.value === item.value
              ) > -1
            }
            label={item?.label}
            setter={() => {
              handleChange({
                parentAccessId: parentAccessId,
                selectedItem: item,
                subModuleId: data?.accessId,
              });
            }}
          />
        ))}
      </div>
    </div>
  );
};

// const CheckBoxItem = ({ label, value, setter }) => {
//   return <MyCheckBox value={value} setter={setter} label={label} />;
// };
