import { useFormik } from "formik";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import HeaderAction from "../../../../../components/HeaderAction";
import MyButton from "../../../../../components/MyButton";
import MyCheckBox from "../../../../../components/MyCheckBox";
import MyInput from "../../../../../components/MyInput";
import MySelect from "../../../../../components/MySelect";
import useGeneralApi from "../../../../../Hooks/useGeneralApi";
import { communityServiceSchema } from "../../../../../schema";
import classes from "./AddOrEditCommunityService.module.css";
import ServiceFieldCard from "./ServiceFieldsCard";

const initialValues = {
  serviceCategoryName: "",
  serviceType: null,
  status: "Recent",
  isActive: true,
  services: [
    {
      serviceName: "",
      serviceCharges: 0,
    },
  ],
};

const AddOrEditCommunityServices = ({
  onFormSubmit,
  onPrevious,
  selectedData,
}) => {
  const { serviceTypeOptions } = useGeneralApi();
  const isEditMode = selectedData !== null; // Check if it's edit mode
  const formik = useFormik({
    initialValues,
    validationSchema: communityServiceSchema,

    onSubmit: async (values, { resetForm }) => {
      try {
        const params = {
          ...(selectedData && {
            serviceCategoryId: selectedData?.serviceCategoryId,
          }),
          ...values,
          serviceTypeId: values?.serviceType?.value,
        };
        delete params["serviceType"];
        console.log("params", params);
        
      } catch (error) {
        toast.error("Error submitting services");
      }
    },
  });

  const {
    touched,
    errors,
    values,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = formik;
  console.log("values services add and edit", values);

  const handleChangeWithTouch = (field) => (value) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
  };

  useEffect(() => {
    if (selectedData) {
      // Populate form with selectedData values when editing
      setValues({
        ...selectedData,
        serviceType: serviceTypeOptions?.find(
          (service) => service?.value === selectedData?.serviceTypeId
        ),
      });
    }
  }, [selectedData, serviceTypeOptions]);

  console.log("selectedData ==>>", selectedData);
  console.log("values << == >>", values);
  return (
    <>
      <HeaderAction
        onClick={onPrevious}
        title={`${selectedData ? "Edit" : "Add"} Service`}
      />
      <form
        onSubmit={handleSubmit}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <section className={classes.controlsWrapper}>
          <h2 className={classes.title}>{selectedData?.serviceCategoryName}</h2>
          <div className={classes["headerActions"]}>
            <MyCheckBox
              name={"active"}
              value={values.isActive}
              setter={() => {
                handleChangeWithTouch("isActive")(!values?.isActive);
              }}
              label={values?.isActive ? "Active" : "Inactive"}
            />
          </div>
          <div className={classes.inputWrapper}>
            <MyInput
              value={values?.serviceCategoryName}
              setter={(e) => {
                handleChangeWithTouch("serviceCategoryName")(e?.target?.value);
              }}
              label={"Enter new service name"}
              error={
                touched?.serviceCategoryName && errors?.serviceCategoryName
              }
            />
          </div>
          <div className={classes.inputWrapper}>
            <MySelect
              error={touched?.serviceType && errors?.serviceType}
              value={values?.serviceType}
              label={"Enter new service name"}
              options={serviceTypeOptions}
              setter={(period) => {
                handleChangeWithTouch("serviceType")(period);
              }}
            />
          </div>

          <div className={classes.listOfItemsWrapper}>
            <h2>Enter Service details</h2>
            <ul className={classes.itemsList}>
              {values?.services?.map((service, index) => (
                <ServiceFieldCard
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChangeWithTouch={handleChangeWithTouch}
                  service={service}
                  key={index}
                  index={index}
                  // onDeleteChildService={}
                  onDelete={() => {
                    const updatedServices = values.services.filter(
                      (_, i) => i !== index
                    );
                    setFieldValue("services", updatedServices);
                  }}
                />
              ))}
            </ul>
            {errors?.services && (
              <p className={classes.servicesError}>
                {!Array.isArray(errors?.services) ? errors?.services : ""}
              </p>
            )}
          </div>
        </section>
        <ul className={classes.addAndView}>
          <li>
            <span
              className={classes.addNew}
              onClick={() => {
                setFieldValue("services", [
                  ...values?.services,
                  {
                    serviceName: "",
                    serviceCharges: 0,
                  },
                ]);
              }}
            >
              Add New
            </span>
          </li>
          <li>
            {" "}
            <span className={classes.records}>
              Records {values?.services?.length}
            </span>
          </li>
        </ul>
        <div className={classes.ctaWrapper}>
          <div className={classes.cta}>
            <MyButton
              type="submit"
              handler={() => {
                setFieldValue("status", "Recent");
                handleSubmit();
              }}
              btnText={isEditMode ? "Update" : "Add"}
              className={classes.saveBtn}
            />

            <MyButton
              type="submit"
              btnText={"Draft"}
              className={classes.draftBtn}
              handler={() => {
                setFieldValue("status", "Draft");
                handleSubmit();
              }}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default AddOrEditCommunityServices;
