import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import styles from "./SignUp.module.css";
import { Box, Grid } from "@mui/material";
import SignUpInitialStep from "./SignUpInitialStep";
import SignUpCreate from "./SignupCreate";
import SignUpJoin from "./SignUpJoin";

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [steps, setSteps] = useState(0);
  const [visible, setVisible] = useState(false);

  const buttonHandler = () => {
    navigate("/");
  };

  useEffect(() => {
    setVisible(true);
  }, []);

  const stepsHandler = async (step) => {
    setTimeout(() => {
      setVisible(true);
      setSteps(step);
    }, 300);
  };

  const components = () => {
    if (steps === 0) {
      return (
        <Grid
          container
          spacing={0}
          className={[
            styles.parentWrapper,
            visible ? styles.visible : styles.hidden,
          ].join(" ")}
        >
          <SignUpInitialStep
            createCommunityHandler={() => {
              setVisible(false);
              stepsHandler(1);
            }}
            joinCommunityHandler={() => {
              setVisible(false);
              stepsHandler(2);
            }}
            goBackHandler={buttonHandler}
          />
        </Grid>
      );
    } else if (steps === 1) {
      return (
        <Grid
          container
          spacing={0}
          className={[
            styles.parentWrapper,
            visible ? styles.visible : styles.hidden,
          ].join(" ")}
        >
          <SignUpCreate />
        </Grid>
      );
    } else if (steps === 2) {
      return (
        <Grid
          container
          spacing={0}
          className={[
            styles.parentWrapper,
            visible ? styles.visible : styles.hidden,
          ].join(" ")}
        >
          <SignUpJoin
            goBackHandler={() => {
              setVisible(false);
              stepsHandler(0);
            }}
          />
        </Grid>
      );
    }
  };

  return components();
};

export default SignUp;
