import React from "react";
import MainSettings from "./MainSettings";
import classes from "./Settings.module.css";
import PaddingContainer from "../../../components/PaddingContainer";
import Header from "../../../components/Header";

const Settings = () => {
  return (
    <PaddingContainer>
      <Header title={"Settings"} />
      <MainSettings />
    </PaddingContainer>
  );
};

export default Settings;
