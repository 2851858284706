import React, { useRef, useState } from "react";
import { FaUser } from "react-icons/fa6";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { IoHome, IoNotifications } from "react-icons/io5";
import ShortcutItem from "../../../components/ShortcutItem";
import classes from "./Shortcuts.module.css";
import { logo, logo1 } from "../../../assets/constant";

const Shortcuts = ({ setIsSidebarClose }) => {
  const sidebarAside = useRef(null);
  const [markAsActive, setMarkAsActive] = useState(1);
  const [activeAction, setActiveAction] = useState("up");

  const handleMarkAsActive = (id) => {
    if (markAsActive < id) {
      setActiveAction("up");
    } else if (markAsActive > id) {
      setActiveAction("down");
    } else {
      setActiveAction(null);
    }
    setMarkAsActive(id);
  };

  return (
    <aside
      className={[
        classes["sidebar-side-transition"],
        classes["sidebar__sideContent"],
      ].join(" ")}
      ref={sidebarAside}
    >
      <div className={classes["sidebarListBefore"]}>
        <img src={logo} />
      </div>
      <div
        className={[
          classes["sidebar__mainMenu"],
          classes["overflow-x-hidden"],
        ].join(" ")}
      >
        <ul className={classes["sidebarList"]}>
          <ShortcutItem
            path={""}
            text={"Dashboard"}
            icon={<IoHome size={20} color="var(--secondary-color)" />}
            active={markAsActive === 1}
            setMarkAsActive={() => handleMarkAsActive(1)}
            activeAction={markAsActive < 1 ? "up" : "down"}
          />

          <ShortcutItem
            path={"notifications"}
            text={""}
            listOfOptions={null}
            icon={
              <IoNotifications
                color="var(--secondary-color)"
                size={18}
                style={{ marginTop: 5 }}
              />
            }
            activeAction={markAsActive < 2 ? "up" : "down"}
            active={markAsActive === 2}
            setMarkAsActive={() => handleMarkAsActive(2)}
          />
          <ShortcutItem
            path={"member"}
            text={""}
            listOfOptions={null}
            icon={
              <FaUser
                color="var(--secondary-color)"
                size={18}
                style={{ marginTop: 5 }}
              />
            }
            active={markAsActive === 3}
            activeAction={markAsActive < 3 ? "up" : "down"}
            setMarkAsActive={() => handleMarkAsActive(3)}
          />
          <ShortcutItem
            text={""}
            listOfOptions={null}
            icon={
              <HiOutlineMenuAlt2
                color="var(--secondary-color)"
                size={18}
                style={{ marginTop: 5 }}
              />
            }
            index={4}
            active={markAsActive === 4}
            setMarkAsActive={setIsSidebarClose}
          />
        </ul>
      </div>
      <div className={classes["sidebarListAfter"]}></div>
    </aside>
  );
};

export default Shortcuts;
