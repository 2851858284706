import useApi from "./useApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { community, login } from "../store/authSlice";
import { loadingHandler } from "../store/loadingSlice";
import { toast } from "react-toastify";

const useLogin = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const phoneRegExp = /^(\(\+\d{1,3}\))?\s?\d{10}$/;

  const form = useFormik({
    initialValues: {
      code: "+92",
      phoneNumber: "",
      password: "",
    },
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      code: Yup.string().required("Required"),
      phoneNumber: Yup.string()
        .required("Required")
        .matches(phoneRegExp, "Phone number is not valid"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(loadingHandler(true));
      api
        .login(values)
        .then((response) => {
          if (response?.data?.headers?.code === 200) {
            dispatch(login(response?.data?.body));
            toast.success(response?.data?.headers?.msg);
          } else {
            toast.error(response?.data?.headers?.msg);
          }
        })
        .finally(() => {
          dispatch(loadingHandler(false));
        });
    },
  });

  return {
    form,
    error,
  };
};

export default useLogin;
