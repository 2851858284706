import React, { useState } from "react";
import classes from "./ServiceList.module.css";
import { HiChevronRight } from "react-icons/hi";
import { TrashIcon, EditIcon } from "../../assets/icons";
import AreYouSureModal from "../../modals/AreYouSure";
const ServiceListCard = ({
  classeName,
  data = null,
  onDeleteServiceCategory,
  onGetCommunityServiceById = () => {},
}) => {
  console.log(data, "datasss");
  const [showAreYouSure, setshowAreYouSure] = useState(false);
  return (
    <>
      <div className={[classes.parentWrapper, classeName].join(" ")}>
        <div className={classes.editAndDeleteAction}>
          <span
            className={classes.roundAction}
            onClick={(e) => {
              e.stopPropagation();
              onGetCommunityServiceById();
            }}
          >
            <EditIcon />
          </span>
          {data?.status != "Deleted" && (
            <span
              className={classes.roundAction}
              onClick={(e) => {
                e.stopPropagation();
                setshowAreYouSure(true);
              }}
            >
              <TrashIcon />
            </span>
          )}
        </div>
        <div className={[classes.wrapper].join(" ")}>
          <h2 className={[classes.title, "text-in-2-line"].join(" ")}>
            {data?.serviceCategoryName}
          </h2>
          <div className={classes.actionWrapper}>
            <span className={classes.indicator}>
              {data?.services?.length} records
            </span>
            <HiChevronRight color={"#AD9BFF"} size={22} />
          </div>
        </div>
      </div>
      <AreYouSureModal
        show={showAreYouSure}
        setShow={setshowAreYouSure}
        handler={() => {
          console.log("typeof handler", typeof handler);
          if (typeof onDeleteServiceCategory == "function") {
            onDeleteServiceCategory(data?.serviceCategoryId);
          }
        }}
      />
    </>
  );
};

export default ServiceListCard;
