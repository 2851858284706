import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import styles from "./SignUpJoin.module.css";
import AuthMainComponent from "../../../../components/AuthMainComponent";
import AuthSubComponent from "../../../../components/AuthSubComponent";
import MyInput from "../../../../components/MyInput";
import MyButton from "../../../../components/MyButton";
import MyCheckBox from "../../../../components/MyCheckBox";
import { useNavigate } from "react-router-dom";
import useSignupJoin from "../../../../Hooks/useSignupJoin";
import Step1 from "./Step1";
import Step2 from "./Step2";
import OTPScreen from "../OTPScreen";
import CommonCard from "../../../../components/CommonCard";
import useGeneralApi from "../../../../Hooks/useGeneralApi";
import Loader from "../../../../components/Loader";
import { useSelector } from "react-redux";

export default function SignUpJoin({ goBackHandler }) {
  const navigate = useNavigate();
  const signupJoin = useSignupJoin();
  const generalApis = useGeneralApi();
  const [error, setError] = useState(false);
  const { loading } = useSelector((state) => state?.loadingReducer);

  const buttonHanler = () => {
    navigate("/");
  };

  const components = () => {
    if (signupJoin.steps === 0) {
      return (
        <Step1
          communityCode={signupJoin.communityCode}
          handleChange={(e) => signupJoin.setCommunityCode(e.target.value)}
          loading={signupJoin.loading}
          handleSubmit={signupJoin.verifyCommunityCode}
          goBackHandler={goBackHandler}
        />
      );
    } else if (signupJoin.steps === 1) {
      return (
        <Step2
          signup={signupJoin}
          buttonHanler={buttonHanler}
          seriesList={signupJoin?.seriesList}
          propertyTypeList={generalApis?.propertyTypeList}
        />
      );
    } else {
      return (
        <CommonCard className={styles.mainWrapper}>
          <OTPScreen
            otp={signupJoin?.otp}
            handleChange={(val) => signupJoin.setOtp(val)}
            handleSubmit={signupJoin.verifyOtpHandler}
            handleFormSubmit={signupJoin.signUpHandler}
            data={signupJoin.form.values}
            error={error}
          />
        </CommonCard>
      );
    }
  };

  return <>{loading ? <Loader /> : components()}</>;
}
