import React, { useState, useEffect } from "react";
import styles from "./Step1.module.css";
import { Box, Grid } from "@mui/material";
import MyInput from "../../../components/MyInput";
import MyButton from "../../../components/MyButton";
import { hidePhoneNumber } from "../../../helpers";
import { FaCircleArrowLeft } from "react-icons/fa6";
import MySelect from "../../../components/MySelect";
import { RiDeleteBin6Line } from "react-icons/ri";
import CommonCard from "../../../components/CommonCard";
import classes from "../OnBoarding.module.css";
import AmenitiesItem from "../../../components/AmenitiesItem";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import AmenitiesComponent from "../../../components/AmenitiesComponent";
import AmenitiesRightComponent from "../../../components/AmenitiesRightComponent";
import AmenityAddComponent from "../../../components/AmmenityAddComponent";

const Step1 = ({ handleSubmit, form, setForm, error, amenitiesArrList }) => {
  const [selectedTab, setSelectedTab] = useState(
    form?.communityAmmenityList[0]?.ammenityID || 0
  );

  useEffect(() => {
    if (form?.communityAmmenityList?.length > 0) {
      setSelectedTab(
        form?.communityAmmenityList[form?.communityAmmenityList?.length - 1]
          ?.ammenityID
      );
    }
  }, [form?.communityAmmenityList]);

  const amenitiesHandler = (item) => {
    const updatedItem = { ...item, communityAmmenitySeriesList: [{ id: 1 }] };
    setForm((prev) => {
      return {
        ...prev,
        communityAmmenityList: prev.communityAmmenityList.some(
          (ele) => ele.ammenityID === updatedItem?.ammenityID
        )
          ? prev.communityAmmenityList.filter(
              (ele) => ele.ammenityID !== updatedItem.ammenityID
            )
          : [...prev.communityAmmenityList, updatedItem],
      };
    });
  };

  const handleDataChange = (id, e, seriesId = 0) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      communityAmmenityList: prev.communityAmmenityList.map((item) =>
        item.ammenityID === id
          ? {
              ...item,
              communityAmmenitySeriesList: item.communityAmmenitySeriesList.map(
                (ele) => (ele.id === seriesId ? { ...ele, [name]: value } : ele)
              ),
            }
          : item
      ),
    }));
  };

  const handleAddSeries = (id) => {
    const newSeriesId =
      form.communityAmmenityList.find((item) => item.ammenityID === id)
        .communityAmmenitySeriesList.length + 1;
    const newSeries = {
      id: newSeriesId,
    };
    setForm((prev) => ({
      ...prev,
      communityAmmenityList: prev.communityAmmenityList.map((item) =>
        item.ammenityID === id
          ? {
              ...item,
              communityAmmenitySeriesList: [
                ...item.communityAmmenitySeriesList,
                newSeries,
              ],
            }
          : item
      ),
    }));
  };

  const handleRemoveSeries = (parentId, id) => {
    setForm((prev) => ({
      ...prev,
      communityAmmenityList: prev.communityAmmenityList
        .map((item) => {
          if (item.ammenityID === parentId) {
            const updatedSeriesList = item.communityAmmenitySeriesList.filter(
              (ele) => ele.id !== id
            );

            return updatedSeriesList.length > 0
              ? { ...item, communityAmmenitySeriesList: updatedSeriesList }
              : null;
          }
          return item;
        })
        .filter((item) => item !== null),
    }));
  };

  return (
    <>
      <CommonCard
        className={[
          classes.mainWrapper,
          classes.extraStyles,
          styles.customCard,
        ].join(" ")}
      >
        <div className={styles.subComponent1}>
          <Box
            sx={{
              "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              position: "relative",
              gap: "10px",
            }}
            className={styles.formComponent}
          >
            <div
              className={[styles.formItem, styles.communityNameWrapper].join(
                " "
              )}
            >
              <MyInput
                name="communityName"
                label="Community Name"
                value={form?.communityName}
                disabled={true}
              />
            </div>
            <div className={styles.formItem}>
              <h1 className={styles.formItemH1}>
                Select amenities of {form?.communityName}
              </h1>
            </div>
            <div className={styles.formItem}>
              <AmenitiesComponent
                amenitiesArr={form?.communityAmmenityList}
                handler={amenitiesHandler}
                styles={styles}
                amenitiesArrList={amenitiesArrList}
                tabHandler={(val) => setSelectedTab(val)}
              />
            </div>
          </Box>
        </div>
        <div>
          <div className={styles.subComponent2}>
            <AmenitiesRightComponent
              amenitiesArr={form?.communityAmmenityList?.find(
                (item) => item?.ammenityID === selectedTab
              )}
              styles={styles}
              handleChange={handleDataChange}
              handleAddSeries={handleAddSeries}
              handleRemoveSeries={handleRemoveSeries}
            />
          </div>
          <div className={styles.btnWrapper}>
            <MyButton
              btnText="Continue"
              className={styles.extraBtnStyle}
              handler={handleSubmit}
              disabled={!form?.communityAmmenityList.length}
            />
          </div>
        </div>
      </CommonCard>
    </>
  );
};

export default Step1;
