import { LuInfo } from "react-icons/lu";
import MyButton from "../../components/MyButton";
import Classes from "./UpdateUserAccessModal.module.css";
import Modal from "@mui/material/Modal";
import React from "react";
import MySelect from "../../components/MySelect";
import MyInput from "../../components/MyInput";

const UpdateUserAccessModal = ({
  show,
  setShow,
  form,
  setForm,
  roleList,
  handler,
  cancelHandler,
  title = "Update User Access",
}) => {
  return (
    <>
      <style>{`
      .modal-sm {
        --bs-modal-width: 350px;
      }
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        open={show}
        onClose={setShow}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className={Classes.container}>
          <div className={Classes.description}>
            <p>{title}</p>
          </div>
          <div className={Classes.inputWrapper}>
            <MyInput
              name="name"
              label="Username"
              value={form?.name}
              setter={(val) => setForm((prev) => ({ ...prev, name: val }))}
              readOnly={true}
              disabled={true}
            />
            <MySelect
              name="role"
              label="RoleName"
              value={form?.role}
              setter={(val) => setForm((prev) => ({ ...prev, role: val }))}
              options={roleList?.map((item) => ({
                label: item?.roleName,
                value: item?.roleId,
              }))}
            />
          </div>

          <div className={Classes.actionsWrapper}>
            <MyButton
              btnText={"Cancel"}
              handler={cancelHandler}
              className={Classes["cancel-button"]}
            />
            <MyButton
              btnText={"Confirm"}
              handler={() => {
                setShow(false);
                if (typeof handler == "function") {
                  handler();
                }
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateUserAccessModal;
