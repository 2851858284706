import React from "react";
import { Box, Grid } from "@mui/material";
import MySelect from "../../../../../components/MySelect";
import classes from "./PreviewAnouncement.module.css";
import MyInput from "../../../../../components/MyInput";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteButton from "../../../../../components/DeleteButton";
import MyCheckBox from "../../../../../components/MyCheckBox";
import EditButton from "../../../../../components/EditButton";
import AnnouncementCard from "../../../../../components/AnnouncementCard";
import MyButton from "../../../../../components/MyButton";
import HeaderAction from "../../../../../components/HeaderAction";
const PreviewAnouncement = ({
  selectedData,
  handleDelete,
  handleEdit,
  onPrevious,
}) => {
  console.log("viewselectedData", selectedData);
  return (
    <>
      <HeaderAction onClick={onPrevious} title={"Announcements preview"} />
      <div className={classes["headerActions"]}>
        <MyCheckBox
          name={"active"}
          value={selectedData?.active}
          label={selectedData?.active ? "Active" : "InActive"}
        />
        <div className={classes["actionsWrapper"]}>
          <DeleteButton handler={handleDelete} />
          <EditButton handler={() => handleEdit(selectedData)} />
        </div>
      </div>

      <ul className={classes.wrapper}>
        <li className={classes.ctaWrapper}>
          <span className={classes.indicator}></span>
          <span className={classes.date}></span>
        </li>

        <li className={classes.subjectWrapper}>
          <h2>Announcement Title</h2>
          <p className={classes.subjectTitle}>{selectedData?.title}</p>
        </li>
        <li className={classes.descriptionWrapper}>
          <h2>Description</h2>
          <p> {selectedData?.description}</p>
        </li>
      </ul>
      <div className={classes.ctaBottomWrapper}>
        <div className={classes.actionWrapper}>
          <MyButton
            handler={() => {
              onPrevious();
            }}
            btnText={"Back"}
            className={classes.action}
          />
        </div>
      </div>
    </>
  );
};

export default PreviewAnouncement;
