import useApi from "./useApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { otpReducer } from "../store/authSlice";
import { toast } from "react-toastify";
import { loadingHandler } from "../store/loadingSlice";

const useSignup = () => {
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [steps, setSteps] = useState(0);
  const [otp, setOtp] = useState("");
  const [user, setUser] = useState({
    name: "",
    code: "+92",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsNcond: false,
    communityName: "",
    communityAddress: "",
  });
  const phoneRegExp = /^(\(\+\d{1,3}\))?\s?\d{10}$/;

  const form = useFormik({
    initialValues: user,
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      code: Yup.string().required("Required"),
      phoneNumber: Yup.string()
        .required("Required")
        .matches(phoneRegExp, "Phone number is not valid"),
      email: Yup.string()
        .email("Email Address is not valid")
        .required("Required"),
      termsNcond: Yup.string().required("Required"),
      password: Yup.string()
        .required("Password is required")
        .min(5, "Your password is too short."),
      confirmPassword: Yup.string()
        .required("Password is required")
        .oneOf([Yup.ref("password")], "Passwords must match"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(loadingHandler(true));
      api
        .generateOtp({
          code: values?.code,
          phoneNumber: values?.phoneNumber,
        })
        .then((res) => {
          if (res?.data?.headers.code === 200) {
            dispatch(otpReducer(res?.data?.body));
            setUser(values);
            setSteps(1);
            toast.success(res?.data?.headers?.msg);
          } else {
            toast.error(res?.data?.headers?.msg);
          }
        })
        .finally(() => {
          dispatch(loadingHandler(false));
        });
    },
  });

  const verifyOtpHandler = (e) => {
    e.preventDefault();
    dispatch(loadingHandler(true));
    api
      .verifyOtp({
        otpCode: otp,
      })
      .then((res) => {
        if (res?.data?.headers.code === 200) {
          dispatch(otpReducer(res?.data?.body));
          setSteps(2);
        } else {
          toast.error(res?.data?.headers?.msg);
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  };

  const signUpHandler = async () => {
    dispatch(loadingHandler(true));
    api
      .signUp({
        name: user?.name,
        email: user?.email,
        code: user?.code,
        phoneNumber: user?.phoneNumber,
        password: user?.password,
        communityName: user?.communityName,
        communityAddress: user?.communityAddress,
      })
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success(response?.data?.body?.message);
          cancelHandler();
          navigate("/");
        } else {
          toast.error(response?.data?.headers?.message);
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const cancelHandler = () => {
    setUser({
      name: "",
      code: "+92",
      phoneNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      termsNcond: false,
      communityName: "",
      communityAddress: "",
    });
    setOtp("");
    setError(false);
    setSteps(0);
  };

  return {
    otp,
    setOtp,
    error,
    form,
    user,
    steps,
    setSteps,
    verifyOtpHandler,
    signUpHandler,
    handleChange,
    signUpHandler,
  };
};

export default useSignup;
