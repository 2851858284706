import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddButton from "../../../../components/AddButton";
import FilterButton from "../../../../components/FilterButton";
import Header from "../../../../components/Header";
import Loader from "../../../../components/Loader";
import PaddingContainer from "../../../../components/PaddingContainer";
import SearchBox from "../../../../components/SearchBox";
import UserRoleItem from "../../../../components/UserRoleItem";
import useGeneralApi from "../../../../Hooks/useGeneralApi";
import useUserRole from "../../../../Hooks/useUserRole";
import AddRole from "./add-role";
import classes from "./user-role.module.css";

const UserRole = () => {
  const accessToken = useSelector((state) => state?.authReducer?.accessToken);
  const { loading } = useSelector((state) => state?.loadingReducer);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { roleList } = useGeneralApi();

  const {
    roleListing,
    getRoleById,
    openAddModal,
    setOpenAddModal,
    modules,
    handleGetAllModulesListing,
    form,
    submitHandler,
    setForm,
    cancelHandler,
    selectAllHandler,
    handleChange,
  } = useUserRole();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PaddingContainer>
          {!openAddModal ? (
            <>
              <Header title={"User Role"} />
              <div className={classes["searchWrapper"]}>
                <SearchBox label={"Search"} name={"search"} />
                <AddButton
                  handler={() => {
                    setOpenAddModal(true);
                    handleGetAllModulesListing();
                  }}
                />
              </div>
              <div className={classes["tabs-section"]}>
                <FilterButton text={"User Role"} active={true} />
                <FilterButton text={"User Access"} />
                <FilterButton text={"President"} />
                <FilterButton text={"Supervisor"} />
              </div>
              <div className={classes["subSection"]}>
                <div className={classes["members-heading"]}>
                  <h1>User Role</h1>
                  <p>
                    {roleListing?.length < 9
                      ? `0${roleListing?.length}`
                      : roleListing?.length}{" "}
                    Records
                  </p>
                </div>
                {roleListing?.map((item, index) => (
                  <UserRoleItem item={item} key={index} onClick={getRoleById} />
                ))}
              </div>
            </>
          ) : (
            <AddRole
              data={modules}
              cancelHandler={cancelHandler}
              handleChange={handleChange}
              selectAllHandler={selectAllHandler}
              form={form}
              setForm={setForm}
              submitHandler={submitHandler}
              roleList={roleList}
            />
          )}
        </PaddingContainer>
      )}
    </>
  );
};

export default UserRole;
