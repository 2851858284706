import React, { useState } from "react";
import useApprovals from "../../../../Hooks/useApprovals";
import useGeneralApi from "../../../../Hooks/useGeneralApi";
import useLoader from "../../../../Hooks/useLoader";
import ApprovalCard from "../../../../components/ApprovalCard";
import FilterButton from "../../../../components/FilterButton";
import Header from "../../../../components/Header";
import Loader from "../../../../components/Loader";
import PaddingContainer from "../../../../components/PaddingContainer";
import classes from "./Approvals.module.css";
import EditApproval from "./EditApproval";
import PreviewApproval from "./PreviewApproval";

const Approvals = () => {
  const {
    approvalsList,
    approvalStatusList,
    approvalStatus,
    setApprovalStatus,
    approvalPreviewForm,
    getApprovalById,
    setApprovalActionStatus,
  } = useApprovals();
  const { loading } = useLoader();
  const { roleList, propertyTypeList } = useGeneralApi();
  const [openPreview, setOpenPreview] = useState(false);

  const handlePreview = (id) => {
    getApprovalById(id);
    setOpenPreview(true);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PaddingContainer>
          {!openPreview ? (
            <>
              <Header title={"Approvals"} />
              <div className={classes["headerWrapper"]}>
                <div className={classes["tabs-section"]}>
                  <FilterButton
                    text={"All"}
                    active={approvalStatus === 0}
                    onClick={() => setApprovalStatus(0)}
                  />
                  {approvalStatusList?.map((item, index) => (
                    <FilterButton
                      key={index}
                      text={item?.approvalStatusName}
                      active={approvalStatus === item?.approvalStatusId}
                      onClick={() => setApprovalStatus(item?.approvalStatusId)}
                    />
                  ))}
                </div>
              </div>
              <div className={classes["subSection"]}>
                {/* <h1 className={classes["sub-heading"]}>Approvals</h1> */}
                {approvalsList.length > 0
                  ? approvalsList?.map((item, index) => (
                      <ApprovalCard
                        data={item}
                        isSetting={true}
                        handler={handlePreview}
                        isPending={item?.approvalStatus?.approvalStatusId === 1}
                      />
                    ))
                  : ""}
              </div>
            </>
          ) : approvalPreviewForm?.values?.approvalStatus?.approvalStatusId ===
            1 ? (
            <EditApproval
              form={approvalPreviewForm}
              name="Approval Form"
              roleList={roleList}
              typeList={propertyTypeList}
              disabled={true}
              closePreview={() => setOpenPreview(false)}
              handleAccept={() => setApprovalActionStatus(2)}
              handleReject={() => setApprovalActionStatus(3)}
            />
          ) : (
            <PreviewApproval
              data={approvalPreviewForm?.values}
              header="Preview Approval"
              closePreview={() => setOpenPreview(false)}
            />
          )}
        </PaddingContainer>
      )}
    </>
  );
};

export default Approvals;
