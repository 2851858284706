import React from "react";
import { Box, Grid } from "@mui/material";
import MySelect from "../../../../../components/MySelect";
import classes from "./EditApproval.module.css";
import MyInput from "../../../../../components/MyInput";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteButton from "../../../../../components/DeleteButton";
import MyCheckBox from "../../../../../components/MyCheckBox";
import MyButton from "../../../../../components/MyButton";
import EditButton from "../../../../../components/EditButton";
import ComplainCard from "../../../../../components/ComplainCard";
import ApprovalCard from "../../../../../components/ApprovalCard";
import UserDetailsForm from "../../../../../components/UserDetailsForm";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";

const EditApproval = ({
  form,
  name,
  roleList,
  typeList,
  disabled,
  handleDelete,
  handleAccept,
  handleReject,
  closePreview,
}) => {
  return (
    <>
      <div className={classes["headerActions"]}>
        <div className={classes["header"]}>
          <MdCancel
            color={"var(--main-color)"}
            size={40}
            onClick={closePreview}
          />
          <p>{name}</p>
        </div>
        {/* <MyCheckBox
          name={"active"}
          value={true}
          label={form?.values?.active ? "Active" : "InActive"}
        /> */}
        <div className={classes["actionsWrapper"]}>
          <DeleteButton handler={handleDelete} />
        </div>
      </div>
      {form?.values && (
        <UserDetailsForm
          form={form}
          name="Request Join Form"
          roleList={roleList}
          typeList={typeList}
          disabled={true}
        >
          <div className={classes["btnWrapper"]}>
            <MyButton
              handler={handleReject}
              type="submit"
              btnText={"Reject"}
              className={classes["btnFormStylesReject"]}
            />
            <MyButton
              handler={handleAccept}
              type="submit"
              btnText={"Accept"}
              className={classes["btnFormStyles"]}
            />
          </div>
        </UserDetailsForm>
      )}
    </>
  );
};

export default EditApproval;

// <MyButton
//               handler={closePreview}
//               btnText={"Close"}
//               className={classes["btnFormStyles"]}
//             />
